/* HeaderLayout.css */

/* Adjust the layout container and header styles */
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: #f8f9fa;
  padding: 10px;
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
}

.user-email {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.p-tieredmenu {
  top: 50px !important; /* Ensure proper positioning */
  left: auto !important;
  right: 0 !important; /* Aligns the dropdown to the right */
  width: 200px; /* Adjust dropdown width */
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 0; /* Remove extra padding */
  margin: 0; /* Remove margins to eliminate extra space */
}

.p-tieredmenu ul {
  padding: 0;
  margin: 0;
}

/* Adjust the link and text within the menu items */
.p-tieredmenu .p-menuitem-link {
  border-radius: 8px !important; /* Set curved corners */
  background-color: white;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 10px 15px; /* Add padding for a better look */
  display: flex;
  align-items: center;
  text-decoration: none !important; /* Remove underline from text */
}

/* Adjust the text */
.p-tieredmenu .p-menuitem-text {
  font-size: 14px;
}

/* Highlight effect as a rectangle with curved corners */
.p-tieredmenu .p-menuitem-link:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px; /* Ensure the corners remain curved */
  text-decoration: none; /* Ensure no underline appears on hover */
}

/* Adjust button styles */
.p-button-rounded {
  border-radius: 8px; /* Set the button to have subtle rounded corners */
}

.p-button {
  margin-left: 10px;
  text-decoration: none !important; /* Remove underline from buttons */
}

/* Sidebar and nav items */
.sidebar {
  width: 250px;
  background-color: #343a40;
  padding-top: 20px;
}

.nav-item {
  margin-bottom: 5px;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: white;
  text-decoration: none;
}

.nav-link:hover {
  background-color: #495057;
  border-radius: 8px; /* Make sure the sidebar links also have curved corners */
  text-decoration: none !important; /* Remove underline from links */
}

/* Main content area */
.main-content {
  flex-grow: 1;
  padding: 20px;
}
