:root {
    --primary-color: #D95D39;
    --accent-color: #555555;
    --background-home-section: #2c3e50;
    --main-background: #2c3e50;
    --text-color: #dcdcdc;
    --shadow: rgba(0, 0, 0, 0.1);
    --bluish-color: #2c3e50; /* Bluish color */
    --navbar-background: rgba(44, 62, 80, 0.9); /* Faded navbar color */
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--main-background);
    color: var(--text-color);
    font-family: 'Avenir', sans-serif;
}

.landing-hero {
    padding: 1.25rem;
    margin-left: 5.625rem;
    margin-right: 5.625rem;
    position: relative;
    margin-top: 6rem; /* Add margin-top to push content below the navbar */
}

.landing-hero::before,
.landing-hero::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    filter: blur(6.25rem);
    z-index: -1;
}

.landing-hero::before {
    opacity: 0.65;
    background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
    width: 25rem;
    height: 25rem;
    top: -8.25rem;
    left: -8.25rem;
}

.landing-hero::after {
    background: linear-gradient(135deg, var(--accent-color), var(--primary-color));
    opacity: 0.8;
    width: 25rem;
    height: 25rem;
    top: 32.925rem;
    left: calc(100% - 25rem);
}

.landing-hero .hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 0;
}

.landing-hero .hero-text {
    max-width: 45%;
    color: var(--text-color);
}

.landing-hero .hero-text h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.landing-hero .hero-text p {
    line-height: 1.8;
    margin-bottom: 4rem;
}

.landing-hero .hero-image img {
    margin-left: 7.25rem;
    max-width: 80%;
    height: auto;
}

.landing-hero .features {
    display: flex;
    justify-content: space-around;
    padding: 2.5rem 0;
    background-color: var(--bluish-color); /* Changed to bluish color */
    margin-top: 6.75rem;
    border-radius: 4rem;
}

.landing-hero .feature {
    text-align: center;
    max-width: 30%;
}

.landing-hero .feature-icon {
    color: var(--primary-color);
}

.landing-hero .feature h4 {
    color: var(--text-color);
    margin-bottom: 1rem;
}

.landing-hero .feature p {
    color: var(--text-color);
}

.faq1-section {
    margin-top: 3rem;
    padding: 2.5rem 0;
    background-color: transparent;
}

.faq1-section h5 {
    text-align: center;
    color: var(--text-color);
}

.faq1-item {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--shadow);
    cursor: pointer;
}

.faq1-question {
    display: flex;
    justify-content: space-between;
}

.faq1-q {
    font-weight: 700;
    color: var(--text-color);
}

.faq1-arrow {
    margin-top: 1.25rem;
    margin-right: 1.5rem;
    transition: transform 0.3s ease;
}

.faq1-arrow.open {
    transform: rotate(90deg);
}

.footer {
    padding: 1.25rem 0;
    background-color: var(--bluish-color); /* Changed to bluish color */
    text-align: center;
    z-index: -2;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
}

.footer p {
    margin: 0;
    color: var(--text-color);
}

.socials a {
    margin: 0 0.625rem;
    color: var(--text-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

.socials a:hover {
    color: var(--primary-color);
}

/* Button styles */
.login-button,
.signup-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 10px;
}

.login-button:hover,
.signup-button:hover {
    background-color: var(--accent-color);
}

/* Media queries for responsiveness */
@media (max-width: 54rem) {
    .landing-hero {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    .landing-hero::after {
        display: none;
    }

    .landing-hero .hero-content {
        flex-direction: column;
        text-align: center;
    }

    .landing-hero .hero-text {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .landing-hero .features {
        flex-direction: column;
    }

    .landing-hero .feature {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .landing-hero h1 {
        font-size: 2.369rem;
    }

.view-all-button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
  
  .view-all-button {
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-all-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  .view-all-link {
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .view-all-link:hover h6 {
    color: #0056b3; /* Darker blue on hover */
  }
  
}
