/* General Layout */
.welcome-container {
  max-width: 100%;
  margin: 50px auto;
  padding: 40px;
  background-color: #ffffff; /* Pure white background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
  font-family: 'Arial', sans-serif;
  color: #333; /* Neutral text color */
}

/* Welcome Title */
.welcome-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50; /* Darker blue-gray */
  margin-bottom: 20px;
  text-align: center; /* Center the title */
}

/* Welcome Message */
.welcome-message {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}

/* List Styling */
.welcome-list {
  margin: 20px 0;
  padding: 0 0 0 20px;
  list-style: disc;
}

.welcome-list li {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #34495e; /* Slightly darker text for lists */
}

/* Disclosure Styling (if applicable) */
.disclosure {
  margin-top: 30px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .welcome-container {
    padding: 20px;
    margin: 20px;
  }

  .welcome-title {
    font-size: 2rem;
  }

  .welcome-message {
    font-size: 1rem;
  }

  .welcome-list li {
    font-size: 1rem;
  }
}

/* Disclosure */
.disclosure-container {
  width: 100%; /* Matches the width of the content area */
  background-color: #f5f5f5; /* Light gray for contrast */
  padding: 15px 30px; /* Comfortable padding */
  border-top: 2px solid #ddd; /* Separation from content */
  text-align: center; /* Centers the text */
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1); /* Adds depth */
}
