.help-and-feedback-container {
    margin: 20px auto;
    max-width: 800px;
    font-family: Arial, sans-serif;
}

.help-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333; /* Black color for the title */
}

.help-section, .feedback-section {
    margin-bottom: 30px;
}

.faq-link {
    color: #d95d39; /* Match link color with the orange theme */
    text-decoration: none;
}

.faq-link:hover {
    text-decoration: underline;
    color: #b84b31; /* Darker orange on hover */
}

.feedback-textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #d95d39; /* Orange border to match the theme */
    border-radius: 5px;
    font-size: 16px;
    resize: none;
}

.feedback-textarea:focus {
    border-color: #b84b31; /* Darker orange on focus */
    box-shadow: 0 0 0 3px rgba(217, 93, 57, 0.25); /* Subtle orange shadow on focus */
}

.feedback-submit-button {
    background-color: #d95d39; /* Orange button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.feedback-submit-button:hover {
    background-color: #b84b31; /* Darker orange on hover */
}

.feedback-status-message {
    margin-top: 10px;
    font-size: 14px;
    color: #d95d39; /* Orange for success message */
}
