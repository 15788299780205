/* General Form Styling */
.form-container {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff; /* Uniform white background */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for form container */
    font-family: Arial, sans-serif;
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Fieldset Styling */
  .fieldset {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .legend {
    padding: 0 5px;
    font-weight: bold;
    color: #555;
  }
  
  /* Label and Input Styling */
  .label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .input {
    width: calc(100% - 12px);
    padding: 6px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  
  /* Error Input Styling */
  .input.error {
    border-color: #e74c3c;
    background-color: #fdd;
  }
  
  /* Error Message Styling */
  .error-message {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
  }
  
  /* Button Styling */
  .button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  
  .button:hover {
    background-color: #218838;
  }
  
  .button:active {
    background-color: #1e7e34;
  }
  
  .button:disabled {
    background-color: #94d3a2;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .form-container {
      padding: 15px;
    }
  
    .input {
      width: 100%;
    }
  }
  