.client-item-header {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 0.5fr; /* Consistent column layout */
  align-items: center;
  padding: 0.75rem 1rem; /* Adjust padding for uniform height */
  background-color: #4b5c6d; /* Darker background for header */
  color: #ffffff; /* White text for readability */
  font-weight: bold;
  border-radius: 5px;
  font-size: 1rem; /* Consistent font size */
  text-align: left; /* Align text to the left */
}

.client-item {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 0.5fr; /* Match header layout */
  align-items: center;
  padding: 0.75rem 1rem; /* Match header padding */
  background-color: #ffffff; /* White background for rows */
  color: #2c3e50; /* Dark text for readability */
  border: 1px solid #dcdcdc; /* Subtle border to separate rows */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: left; /* Align text to the left */
  font-size: 0.9rem; /* Slightly smaller font for rows */
}

.client-item:hover {
  background-color: #f5f5f5; /* Light gray on hover for better UX */
}

.client-info {
  display: contents; /* Ensure alignment with header */
  cursor: pointer;
}

.client-info p {
  margin: 0;
  padding: 0.5rem; /* Adjust padding for consistency */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Handle long text gracefully */
}

.search-bar {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 1rem;
}

.search-bar input {
  height: 40px; /* Match height with Add Client button */
  padding: 0 1rem; /* Adjust padding */
  border-radius: 5px;
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 1rem;
  font-size: 1rem; /* Consistent font size */
}

.search-bar-client button {
  height: 40px; /* Match height with input */
  padding: 0 1.5rem; /* Adjust padding for button */
  background-color: #d95d39; /* Primary button color */
  border: none;
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.search-bar button:hover {
  background-color: #c04e2e; /* Darker shade on hover */
}

.client-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Reduce gap between rows */
}

.three-dot-menu {
  background-color: transparent;
  border: none;
  color: #2c3e50; /* Match text color with rows */
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
}

.three-dot-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.25rem;
  background-color: #4b5c6d; /* Match header background */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.three-dot-dropdown-item {
  padding: 0.5rem 1rem;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.three-dot-dropdown-item:hover {
  background-color: #5d6e7f; /* Slightly lighter hover effect */
}

.clients h1 {
  text-align: center;
  color: #1a1a1a; /* Darker shade for the title */
  margin-bottom: 1.5rem;
  font-weight: bold; /* Make it stand out more */
  font-size: 2rem; /* Adjust the size for emphasis */
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4CAF50; /* Success banner color */
  color: white;
  text-align: center;
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.5s ease-out, fadeOut 0.5s 3.5s ease-out forwards;
}
