.goals-form {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .goals-form h2 {
    font-size: 18px;
    color: #333; /* Darker color for better contrast */
    margin-bottom: 20px;
  }
  
  .goals-form div {
    margin-bottom: 15px;
  }
  
  .goals-form label {
    font-size: 16px;
    color: #222; /* Darker color for readability */
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  /* Checkbox Styling */
  .goals-form input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: #007bff; /* Modern blue color */
    cursor: pointer;
    border-radius: 4px; /* Optional: Rounded corners */
    margin: 0; /* Removes any default margin */
  }
  
  /* Hover effect for better interaction */
  .goals-form label:hover {
    color: #0056b3; /* Slightly darker blue for hover effect */
  }
  
  /* Text for descriptions */
  .goals-form p {
    font-size: 14px;
    color: #555; /* Neutral gray for less emphasis */
    margin: 5px 0 0 25px;
  }
  
  /* Disclaimer */
  .goals-form .disclaimer {
    font-size: 12px;
    color: #777; /* Subtle gray for disclaimer text */
    margin-top: 20px;
  }
  