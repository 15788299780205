/* .comparison-section {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    border-radius: 8px;
    margin-top: 20px;
  } */
  
  .comparison-section h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .comparison-section .comparison-container {
    overflow-x: auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    border-radius: 8px;
  }
  
  .comparison-section table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .comparison-section th, .comparison-section td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .comparison-section th {
    background-color: #f8f9fa;
    color: #333;
  }
  
  .comparison-section tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .comparison-section tr:hover {
    background-color: #e9ecef;
  }
  
  .highlighted {
    background-color: #e9ecef !important;
  }
  
  .editable-span {
    cursor: pointer;
    border: 1px dotted transparent;
    transition: border-color 0.3s;
  }
  
  .editable-span:hover {
    border-color: #ccc;
  }
  
  .editable-field {
    font-size: 1rem;
    padding: 0.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  