:root {
    --primary-color: #d95d39;
    --primary-color-text: #ffffff;
    --text-color: #ffffff;
    --input-border-color: #d95d39;
    --button-hover-color: #b84b31;
}

/* Overrides for specific PrimeReact elements */
.p-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--primary-color-text);
}

.p-button:hover {
    background-color: var(--button-hover-color);
    border-color: var(--button-hover-color);
}

.p-inputtext:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 0.2rem rgba(217, 93, 57, 0.25);
}

.p-dropdown .p-dropdown-label {
    color: var(--primary-color);
}