/* Sidebar dropdown container */
.slider-section {
  width: 90%; /* Indent from the left */
  margin-left: 10%; /* Moves dropdowns away from navbar */
  background: #2c3e50;
  padding: 0;
  border-radius: 6px;
  margin-bottom: 10px;
}

/* Dropdown button styling */
.dropdown-header {
  width: 100%;
  background: #3a506b; /* Match sidebar */
  color: #dcdcdc;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Hover effect */
.dropdown-header:hover {
  background: #455a75;
}

/* Dropdown arrow (smaller icon) */
.dropdown-icon {
  font-size: 0.8rem;
  opacity: 0.8;
}

/* Dropdown content */
.dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  background: #1e293b;
  border-radius: 0 0 6px 6px;
  margin-top: -5px;
}

/* Hide scrollbar for sidebar sliders */
.sidebar-sliders {
  overflow-y: auto; /* Allows scrolling */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

/* Hide scrollbar in Webkit-based browsers (Chrome, Safari) */
.sidebar-sliders::-webkit-scrollbar {
  display: none;
}
