.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  h3 {
    text-align: center;
    color: #007bff;
  }
  
  .calculation-summary p {
    margin: 5px 0;
  }
  
  ul {
    margin-top: 15px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  