/* src/components/landing/hero/Hero.css */

.landing-hero {
  padding: 1.25rem;
  margin-left: 5.625rem;
  margin-right: 5.625rem;
  position: relative;
}

.landing-hero::before,
.landing-hero::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  filter: blur(6.25rem);
  z-index: -1;
}

.landing-hero::before {
  opacity: 0.65;
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color));
  width: 25rem; /* Increased size */
  height: 25rem; /* Increased size */
  top: -8.25rem; /* Adjusted position */
  left: -8.25rem; /* Adjusted position */
}

.landing-hero::after {
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
  opacity: 0.8;
  width: 25rem; /* Increased size */
  height: 25rem; /* Increased size */
  top: 32.925rem;
  left: calc(100% - 25rem); /* Adjusted position */
}

.landing-hero .hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
}

.landing-hero .hero-text {
  max-width: 45%;
}

.landing-hero .hero-text p {
  line-height: 1.8;
  margin-bottom: 4rem;
}

.landing-hero .hero-image img {
  margin-left: 7.25rem;
  max-width: 80%;
  height: auto;
}

.landing-hero .features {
  display: flex;
  justify-content: space-around;
  padding: 2.5rem 0;
  background-color: var(--background-home-section);
  margin-top: 6.75rem;
  border-radius: 4rem;
}

.landing-hero .feature {
  text-align: center;
  max-width: 30%;
}

.landing-hero .feature-icon {
  color: var(--accent-color);
}

.landing-hero .feature p {
  color: var(--text-color);
}

.faq-section {
  margin-top: 3rem;
  padding: 2.5rem 0;
  background-color: transparent;
}

.faq-section h5 {
  text-align: center;
}

.faq-item {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--shadow);
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
}

.faq-q {
  font-weight: 700;
}

.faq-arrow {
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  transition: transform 0.3s ease;
}

.faq-arrow.open {
  transform: rotate(90deg);
}

.footer {
  padding: 1.25rem 0;
  background-color: var(--main-background);
  text-align: center;
  z-index: -2;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
}

.footer p {
  margin: 0;
  color: var(--text-color);
}

.socials a {
  margin: 0 0.625rem;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.socials a:hover {
  color: var(--accent-color);
}

/* Media queries for responsiveness */
@media (max-width: 54rem) {
  .landing-hero {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .landing-hero::after {
    display: none;
  }

  .landing-hero .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .landing-hero .hero-text {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .landing-hero .hero-image {
    display: none;
  }

  .landing-hero .features {
    flex-direction: column;
  }

  .landing-hero .feature {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .landing-hero h1 {
    font-size: 2.369rem;
  }
}
