.verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #D95D39, #383838);
    color: #fff;
    font-family: 'Lato', sans-serif;
  }
  
  .verification-card {
    background: #fff;
    color: #383838;
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .verification-card.error {
    background: #ffe6e6;
    color: #d9534f;
  }
  
  .verification-card h1 {
    margin-bottom: 10px;
    font-size: 2.5rem;
  }
  
  .verification-card p {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  
  .verification-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background: #D95D39;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .verification-btn:hover {
    background-color: #A64529;
  }
  
  .verification-btn:focus {
    outline: none;
  }
  