/* src/styling/Chatbot.css */

.chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chatbot-toggle {
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatbot-header {
    background: #007bff;
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    position: relative;
}

.chatbot-header h3 {
    margin: 0;
    font-size: 18px;
    text-align: center;
    flex: 1; /* Ensures it takes up all available space */
}

.close-icon {
    position: absolute;
    right: 10px; /* Keep close icon aligned to the right */
    cursor: pointer;
}


.chatbot-window {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.chatbot-history {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Add space between messages */
}

.message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 16px;
    max-width: 80%;
    word-wrap: break-word;
    display: inline-block;
}

.message.bot {
    background: #e0e0e0;
    color: #333;
    align-self: flex-start;
}

.message.user {
    background: #007bff;
    color: white;
    align-self: flex-end;
}

.chatbot-input-area {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background: white;
}

.chatbot-input-area input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 8px;
}

.chatbot-input-area .send-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.chatbot-input-area .send-button:hover {
    background: #0056b3;
}

.drag-handle {
    width: 16px;
    height: 16px;
    background: transparent;
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: nwse-resize; /* Diagonal resize cursor */
    z-index: 10;
}

.drag-handle::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent; /* Arrow shape */
    border-top-color: #FFFFFF; /* Arrowhead color */
    transform: rotate(135deg); /* Position arrow diagonally */
    top: 0;
    left: 0;
}

