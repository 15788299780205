.rent-vs-own-breakdown {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .breakdown-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .chart-container {
    flex: 1;
    min-width: 300px;
    max-width: 48%;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .chart-wrapper {
    height: 400px; /* Ensures both charts are the same height */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  