/* src/components/layoutInteraction/HeaderLayout.css */

/* Reset body margin */
body {
    margin: 0;
  }
  
  /* Top Navbar */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50; /* Solid color */
    padding: 0.75rem 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header .logo img {
    height: 2rem;
  }
  
  .header .user-info .custom-btn {
    background-color: transparent;
    border: none;
    color: #dcdcdc;
    font-size: 0.875rem;
    font-weight: bold;
  }
  
  .header .dropdown-menu {
    background-color: #2c3e50;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header .dropdown-item {
    color: #dcdcdc;
  }
  
  .header .dropdown-item:hover {
    background-color: #555555;
    color: #dcdcdc;
  }
  
  .mobile-menu-toggle {
    display: none;
    color: #ffffff;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .mobile-menu {
    position: absolute;
    top: 3.5rem; /* Adjust for the height of the header */
    left: 0;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    background-color: #2c3e50;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 20;
  }
  
  .mobile-menu-item {
    padding: 0.5rem 1rem;
  }
  
  .mobile-menu-item .nav-link {
    color: #dcdcdc;
    text-decoration: none;
  }
  
  /* Sidebar */
  .sidebar {
    background-color: #2c3e50;
    width: 14rem;
    height: calc(100vh - 3.5rem); /* Adjust for the height of the header */
    padding: 1rem 0.5rem;
    position: fixed;
    top: 3.5rem; /* Height of the header */
    left: 0;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 5;
    transition: left 0.3s ease;
  }
  
  .sidebar .nav-item {
    margin: 0.5rem 0;
  }
  
  .sidebar .nav-link {
    display: flex;
    align-items: center;
    color: #dcdcdc;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .sidebar .nav-link:hover,
  .sidebar .nav-link.active {
    background-color: #555555;
    color: #dcdcdc;
  }
  
  .sidebar .nav-link span {
    margin-left: 0.5rem;
  }
  
  /* Main Content */
  .main-content {
    margin-left: 14rem; /* Width of the sidebar */
    padding: 2rem;
    background-color: #f5f0e8;
    color: #dcdcdc;
    height: calc(100vh - 3.5rem); /* Adjust for the height of the header */
    overflow-y: auto;
    margin-top: 3.5rem; /* Adjust for the fixed header height */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 10rem;
    }
  
    .sidebar .nav-link span {
      display: none;
    }
  
    .main-content {
      margin-left: 10rem;
    }
  }
  
  @media (max-width: 576px) {
    .header .logo img {
      display: none;
    }
  
    .mobile-menu-toggle {
      display: block;
    }
  
    .sidebar {
      position: absolute;
      height: auto;
      top: 3.5rem; /* Adjust for the fixed header height */
      left: -14rem;
      transition: left 0.3s ease;
    }
  
    .sidebar.open {
      left: 0;
    }
  
    .main-content {
      margin-left: 0;
      width: 100%;
    }
  }
  
  /* src/components/layoutInteraction/ClientLayout.css */
  
  /* Reset body margin */
  body {
    margin: 0;
  }
  
  /* Top Navbar */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2c3e50; /* Solid color */
    padding: 0.75rem 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header .logo img {
    height: 2rem;
  }
  
  .header .user-info .custom-btn {
    background-color: transparent;
    border: none;
    color: #dcdcdc;
    font-size: 0.875rem;
    font-weight: bold;
  }
  
  .header .dropdown-menu {
    background-color: #2c3e50;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header .dropdown-item {
    color: #dcdcdc;
  }
  
  .header .dropdown-item:hover {
    background-color: #555555;
    color: #dcdcdc;
  }
  
  .mobile-menu-toggle {
    display: none;
    color: #ffffff;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .mobile-menu {
    position: absolute;
    top: 3.5rem; /* Adjust for the height of the header */
    left: 0;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    background-color: #2c3e50;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 20;
  }
  
  .mobile-menu-item {
    padding: 0.5rem 1rem;
  }
  
  .mobile-menu-item .nav-link {
    color: #dcdcdc;
    text-decoration: none;
  }
  
  /* Sidebar */
  .sidebar {
    background-color: #2c3e50;
    width: 14rem;
    height: calc(100vh - 3.5rem); /* Adjust for the height of the header */
    padding: 1rem 0.5rem;
    position: fixed;
    top: 3.5rem; /* Height of the header */
    left: 0;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 5;
    transition: left 0.3s ease;
  }
  
  .sidebar .nav-item {
    margin: 0.5rem 0;
  }
  
  .sidebar .nav-link {
    display: flex;
    align-items: center;
    color: #dcdcdc;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .sidebar .nav-link:hover,
  .sidebar .nav-link.active {
    background-color: #555555;
    color: #dcdcdc;
  }
  
  .sidebar .nav-link span {
    margin-left: 0.5rem;
  }
  
  /* Main Content */
  .main-content {
    margin-left: 14rem; /* Width of the sidebar */
    padding: 2rem;
    background-color: #f5f0e8;
    color: black;
    height: calc(100vh - 3.5rem); /* Adjust for the height of the header */
    overflow-y: auto;
    margin-top: 3.5rem; /* Adjust for the fixed header height */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 10rem;
    }
  
    .sidebar .nav-link span {
      display: none;
    }
  
    .main-content {
      margin-left: 10rem;
    }
  }
  
  @media (max-width: 576px) {
    .header .logo img {
      display: none;
    }
  
    .mobile-menu-toggle {
      display: block;
    }
  
    .sidebar {
      position: absolute;
      height: auto;
      top: 3.5rem; /* Adjust for the fixed header height */
      left: -14rem;
      transition: left 0.3s ease;
    }
  
    .sidebar.open {
      left: 0;
    }
  
    .main-content {
      margin-left: 0;
      width: 100%;
    }
  }
  

/* Fullscreen Mode */
.fullscreen .sidebar {
  display: none; /* Completely remove the sidebar */
}

.fullscreen .main-content {
  margin-left: 0; /* Remove the space for the sidebar */
  width: 100%; /* Expand the main content to occupy the full width */
  height: calc(100vh - 3.5rem); /* Adjust for the header height */
  margin-top: 3.5rem; /* Maintain the fixed header's offset */
  padding: 2rem; /* Keep the padding for content readability */
  background-color: #f5f0e8; /* Preserve the background color */
  overflow-y: auto; /* Ensure scrollability for content */
}

/* Header in Fullscreen Mode */
.fullscreen .header {
  width: 100%; /* Ensure the header spans the full width */
  position: fixed; /* Keep the header fixed at the top */
  top: 0;
  z-index: 10; /* Ensure it stays above the content */
  background-color: #2c3e50; /* Consistent header styling */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Transition for smooth entry and exit of fullscreen mode */
.layout-container {
  transition: all 0.3s ease;
}

/* Mobile Adjustments for Fullscreen Mode */
@media (max-width: 576px) {
  .fullscreen .main-content {
    padding: 1rem; /* Reduce padding on smaller screens */
  }
}

.fullscreen-toggle {
  background: none;
  border: none;
  color: #dcdcdc;
  font-size: 1.5rem; /* Adjust size as needed */
  cursor: pointer;
  margin-left: 1rem; /* Align with other elements */
  transition: color 0.3s ease;
}

.fullscreen-toggle:hover {
  color: #ffffff;
}


/* Completely hide the scrollbar */
.sidebar::-webkit-scrollbar {
  width: 0px; /* Removes scrollbar width */
  display: none; /* Hides it in Chrome/Safari */
}

.sidebar {
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}