/* src/modules/pages/clientPresentation/Analysis.css */
.analysis-page {
    padding: 20px;
  }
  
  .analysis-form label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .analysis-form input,
  .analysis-form select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .analysis-charts {
    margin-top: 20px;
  }
  