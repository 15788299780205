.modal-overlay-tax {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-tax {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80% !important; /* Explicitly set the width to override inherited styles */
  min-width: 800px !important; /* Ensure a minimum width */
  max-width: 1200px !important; /* Optional: Cap the width for large screens */
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for better appearance */
}


.modal-close-tax {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

h3 {
  text-align: center;
  color: #e67e22;
  font-size: 1.5em; /* Slightly larger for better emphasis */
}

.calculation-summary {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.calculation-summary h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
  text-align: left;
}

.summary-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add spacing between items */
  justify-content: space-between; /* Distribute items across the row */
}

.summary-grid div {
  flex: 1 1 45%; /* Allow two items per row on larger screens */
  font-size: 1em;
  color: #555;
}

.summary-grid strong {
  color: #333; /* Make labels slightly bolder */
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 1em; /* Slightly larger text for better readability */
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px; /* Added padding for better spacing */
  text-align: center;
}

th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.highlighted {
  background-color: #f0f8ff; /* Light blue background */
  font-weight: bold;
  color: #000; /* Ensure text remains readable */
}

