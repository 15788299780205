.chart-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .chart {
    flex-grow: 1;
    min-width: 45%;
    max-width: 50%;
    text-align: center;
  }
  