/* Stylesheet for Client Info Form */

/* General Form Styling */
.client-info-form {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
  }
  
  .client-info-form h2 {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Fieldset Styling */
  .client-info-form fieldset {
    /* border: 1px solid #ddd;
    border-radius: 5px; */
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .client-info-form legend {
    text-align: center;
    padding: 0 5px;
    font-weight: bold;
    color: #555;
  }
  
  /* Label and Input Styling */
  .client-info-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  
  .client-info-form input {
    width: calc(100% - 12px);
    padding: 6px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Error Input Styling */
  .client-info-form input.error-input {
    border-color: #e74c3c;
    background-color: #fdd;
  }
  
  /* Error Message Styling */
  .client-info-form .error-message {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
  }
  
  /* Button Styling */
  .client-info-form .save-button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .client-info-form .save-button:hover {
    background-color: #218838;
  }
  
  .client-info-form .save-button:active {
    background-color: #1e7e34;
  }
  
  .client-info-form .save-button:disabled {
    background-color: #94d3a2;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .client-info-form {
      padding: 15px;
    }
  
    .client-info-form input {
      width: 100%;
    }
  }
  