@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  background-color: #ffffff;
}

.title {
  font-family: 'Avenir', sans-serif;
  color: #383838; 
}

.input-group {
  position: relative;
  border: 1px solid #D95D39; 
  border-radius: 5px;
  overflow: hidden;
}

.input-group-text svg {
  color: #D95D39; 
}

.form-control {
  box-shadow: none !important;
  padding-left: 15px; 
  font-family: 'Lato', sans-serif; 
  font-size: 15px;
}


.form-control::placeholder {
  color: #aaa;
  opacity: 1; 
}


.form-control:-ms-input-placeholder {
  color: #aaa;
}


.form-control::-ms-input-placeholder {
  color: #aaa;
}

.login-btn {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #D95D39;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  font-family: 'Avenir', sans-serif;
}

.login-btn:hover {
  background-color: #A64529; 
}

.separator {
  position: relative;
  text-align: center;
  margin: 30px 0;
}

.separator:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  height: 1px;
  background: #ddd;
  z-index: 1;
}

.separator span {
  position: relative;
  background: #fff;
  padding: 0 10px;
  z-index: 2;
  color: #949494; 
  font-size: 14px;
}

.google-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease; 
  font-family: 'Avenir', sans-serif; 
}

.google-btn:hover {
  background-color: #c6382e;
}

.bottom-text {
  font-size: 14px;
  color: #aaa;
  font-family: 'Lato', sans-serif;
  text-decoration: none; 
}

.bottom-text a {
  text-decoration: none; 
}

.register-btn {
  width: 100%;
  height: 50px;
  background-color: #D95D39; 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
  align-self: center;
}

.register-btn:hover {
  background-color: #A64529;
}

.instructions-text {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: #777;
}

.logo-container {
  position: absolute;
  right: -10%; 
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  background: rgb(255, 255, 255);
  padding: 20px; 
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.logo {
  width: 371px; 
  height: auto;
}

.success-message p {
  color: #383838;
  font-size: 16px;
  text-align: center;
}

.text-danger {
  color: #D95D39; 
  font-size: 14px;
  text-align: center;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  color: #383838;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px; /* Limit maximum width */
  font-family: 'Lato', sans-serif;
}
