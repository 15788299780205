.comparison-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.compare-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-home-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.panes {
  display: flex;
  gap: 1rem;
  height: 100%;
}

.pane {
  flex: 1; /* Automatically resize panes equally */
  border: 1px solid #ccc;
  padding: 1rem;
  box-sizing: border-box;
}

.remove-button {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chart-container {
  max-width: 800px;
  margin: auto;
}
