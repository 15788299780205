/* Main container for the edit client page */
.edit-client-container {
  padding: 20px;
  background-color: inherit; /* Retains the existing background color */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header container with title and dropdown aligned horizontally */
.edit-client-header-container {
  text-align: center;
}

/* Title styling */
.edit-client-header {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin: 30; /* Remove extra margin for better alignment */
}

/* Dropdown styling */
.client-selection-dropdown {
  width: 100%;
  max-width: 300px; /* Adjust the dropdown width */
}

.client-selection-dropdown .p-dropdown {
  width: 100%;
}

/* Tabs Container */
.edit-client-tabs {
  display: flex;
  justify-content: space-around; /* Space tabs evenly */
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Darker gradient background */
  padding: 1rem 0;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Depth with shadow */
  margin-bottom: 20px; /* Spacing from the content below */
  transition: background-color 0.3s ease-in-out;
}

/* Individual Tab Button */
.tab-button {
  flex: 1; /* Ensures tabs share equal width */
  text-align: center;
  padding: 10px 20px;
  font-size: 1rem; /* Adjust font size for readability */
  color: white; /* White text for contrast */
  text-decoration: none;
  background: transparent; /* No background for inactive state */
  border: none;
  border-radius: 8px; /* Subtle rounding for buttons */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover and Active States */
.tab-button:hover {
  color: #d3d9d2; /* Slightly lighter text on hover */
  background-color: rgba(255, 255, 255, 0.1); /* Light hover background */
}

.tab-button.active {
  color: #d3d9d2; /* Slightly muted highlight for active tab */
  background-color: rgba(255, 255, 255, 0.2); /* Stronger background for active */
}

/* Responsive Design */
@media (max-width: 768px) {
  .edit-client-tabs {
    flex-direction: column; /* Stack tabs vertically on smaller screens */
    align-items: stretch; /* Ensure full width */
  }

  .tab-button {
    width: 100%; /* Full width for vertical tabs */
    margin-bottom: 10px; /* Space between stacked tabs */
  }
}



/* Input fields styling */
form input, form textarea, form select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa; /* Matches the tabs' color */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s;
}

form input:focus, form textarea:focus, form select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

/* Buttons within forms */
form button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #0056b3;
}
/* Unified Button Container */
.edit-client-buttons {
  display: flex;
  justify-content: space-between; /* Space out buttons evenly */
  gap: 10px; /* Add consistent spacing between buttons */
  margin-top: 20px;
  padding: 10px;
}

/* General Button Styles */
.nav-button {
  flex: 1; /* Ensure buttons have equal width */
  max-width: 200px; /* Limit the maximum button size */
  padding: 8px 15px; /* Smaller padding for compact buttons */
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

/* Back Button */
.back-button {
  background-color: rgba(0, 0, 0, 0.6); /* Neutral gray */
}

.back-button:hover:not(:disabled) {
  background-color: #5a6268; /* Darker gray */
}


/* Save Button */
.save-button {
  background-color: rgba(0, 0, 0, 0.6); /* Primary blue for Save */
}

.save-button:hover:not(:disabled) {
  background-color: #5a6268; /* Darker blue */
}

/* Next Button */
.next-button {
  background-color: rgba(0, 0, 0, 0.6); /* Same as Save for consistency */
}

.next-button:hover:not(:disabled) {
  background-color: #5a6268; /* Darker blue */
}

/* Disabled State */
.nav-button:disabled {
  background-color: #e0e0e0; /* Light gray for disabled */
  color: #aaa;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .edit-client-buttons {
    flex-direction: column; /* Stack buttons on smaller screens */
    align-items: stretch; /* Ensure full-width buttons */
  }

  .nav-button {
    width: 100%; /* Full-width buttons for mobile */
  }
}

