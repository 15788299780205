.faq-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    color: #e2e8f0;
  }

  .faq-category-title {
    font-size: 1.8rem; /* Adjust size as needed */
    font-weight: bold;
    color: #f97316; /* Orange color */
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  
  .faq-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #f97316;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faq-item {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .faq-question {
    width: 100%;
    text-align: left;
    background-color: #f8fafc;
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: #1e293b;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f1f5f9;
  }
  
  .faq-question.active {
    background-color: #f97316;
    color: white;
  }
  
  .faq-icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }
  
  .faq-icon.rotate {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-answer.show {
    max-height: 500px;
    padding: 1rem;
  }
  
  .faq-answer p {
    margin: 0;
    line-height: 1.6;
  }
  
  @media (max-width: 640px) {
    .faq-page {
      padding: 1rem;
    }
  
    .faq-title {
      font-size: 2rem;
    }
  
    .faq-question {
      font-size: 1rem;
    }
  }
  
  