.packet-container {
  /* background-color: #E2E5EA; Light gray background */
  padding: 20px;
  font-size: 0.9rem;
}

.section-wrapper {
  padding: 30px;
}

.form-body {
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  padding: 30px; /* add some space inside */
}

.row {
  display: flex;
  gap: 40px;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  
}

.input-row.address  {
  width: 100% !important;
  overflow:visible;
}

/* .form-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
} */

.form-title {
  font-size: 2rem; /* Large and bold */
  font-weight: 700;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #1f2937; /* Dark gray for strong contrast */
}


.flex-column {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1;
}


/* Styling for Sections */
.section {
  padding: 20px;
  flex: 1;
}


.input-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-label {
  width: 180px;
  font-weight: bold;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 300px; /* Adjust as needed */
}

.input-field {
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  flex-shrink: 0;
}

.symbol-spacer {
  width: 12px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.button-row {
  display: flex;
  justify-content: center; /* or 'center' if you want centered */
  gap: 40px;
  margin-top: 24px;
}

.btn {
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn.update {
  background-color: #e79983;
  color: white;
}

.btn.update:hover {
  background-color: #d95d39;
}

.btn.delete {
  background-color: #5f83a7;
  color: white;
}

.btn.delete:hover {
  background-color: #243144;
}

.toggle-buttons label {
  font-weight: 500;
  font-size: 0.85rem;
  cursor: pointer;
}

.form-wrapper {
  background-color: #ffffff; /* unified background */
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
}