.down-payment-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .down-payment-label {
    font-weight: bold;
  }
  
  .down-payment-toggle {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .toggle-label {
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  
  #toggle-down-payment {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  
  #toggle-down-payment:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  #toggle-down-payment:checked::after {
    content: "✔";
    color: white;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    left: 3px;
    top: -1px;
  }
  