/* src/components/landing/navbar/Navbar.css */

.landing-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--navbar-background); /* Faded color */
    padding: 1rem 2rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  
  .landing-navbar .logo img {
    height: 2.5rem;
  }
  
  .landing-navbar .auth-links {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .landing-navbar .auth-links .login-button,
  .landing-navbar .auth-links .signup-button {
    margin-left: 1rem;
  }
  
  .mobile-menu-toggle {
    display: none;
    color: #ffffff;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 48rem) {
    .landing-navbar .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 4.75rem;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      background-color: var(--background-home-section);
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
      z-index: 10;
      border-radius: 2rem;
      padding: 1rem;
      transition: all 0.3s ease-in-out;
    }
  
    .landing-navbar .logo img {
      margin-left: 1.5rem;
    }
  
    .landing-navbar .nav-links.open {
      display: flex;
    }
  
    .landing-navbar .nav-links li {
      margin: 1rem 0;
      text-align: center;
    }
  
    .landing-navbar .auth-links {
      display: none;
    }
  
    .mobile-menu-toggle {
      margin-right: 1.5rem;
      display: block;
    }
  
    .landing-navbar .nav-links.open .mobile-auth-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }
  
    .landing-navbar .nav-links.open .login-button,
    .landing-navbar .nav-links.open .signup-button {
      margin: 0.5rem 0;
    }
  }
  