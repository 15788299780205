.confirm-email-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .confirm-email-container h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .confirm-email-container p {
    color: #555;
    margin-bottom: 15px;
  }
  
  .confirm-email-container .btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .confirm-email-container .btn:hover {
    background-color: #0056b3;
  }
  