.loan-packet-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  font-size: 0.9rem; /* Base font size reduced slightly */
}

/* General Row Styling */
.row {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  align-items: stretch; /* Ensures all sections have equal height */
}

/* Column Flexbox Utility */
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

/* Styling for Sections */
.section {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  flex: 1;
}

/* Headings */
.loan-packet-container h2, 
.loan-packet-container h3 {
  font-size: 1.25rem; /* Was 1.5rem */
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

/* Paragraphs */
.loan-packet-container p {
  font-size: 0.95rem; /* Was 1rem */
  color: #555;
  line-height: 1.5;
}

/* 🔥 Summary Box - Enhanced Styling */
.summary-box {
  padding: 15px;
  border-radius: 8px;
  min-width: 250px;
  max-width: 320px;
  height: 100%;
  font-weight: bold;
  border: 2px solid #444;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-size: 0.95rem; /* Was 1rem */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Ensuring Summary Box Text Stands Out */
.summary-box p {
  font-size: 1rem; /* Was 1.1rem */
  font-weight: 600;
  color: #222;
}

/* Specific Summary Box Colors */
.tax-benefits {
  background-color: #f7f8f9;
}

.amortization-benefit {
  background-color: #E2E5EA;
}

/* 🔥 Investment Benefit Section */
.investment-benefit {
  background-color: #f7f8f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
}

/* 📌 Investment Table */
.investment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.investment-table th {
  background-color: #dc3545;
  color: white;
  padding: 12px;
  text-align: left;
  font-size: 0.95rem; /* Was 1rem */
}

.investment-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-size: 0.95rem; /* Was 1rem */
  color: #333;
}

.investment-table tr:nth-child(even) {
  background-color: #f5c6cb;
}

.investment-table tr:nth-child(odd) {
  background-color: #ffe6e6;
}

.investment-table th, .investment-table td {
  border: 1px solid #ddd;
}

/* Responsive Font Scaling */
@media (max-width: 768px) {
  .investment-table {
    font-size: 0.85rem;
  }

  .summary-box {
    font-size: 0.9rem;
  }

  .loan-packet-container {
    font-size: 0.85rem;
  }
}

/* 🔹 Home Purchase Details Styling */
.home-purchase-details {
  background-color: #E2E5EA;
}

/* 📄 PDF Viewer Container */
.pdf-viewer-container {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.mortgage-calculator {
  background-color: #E2E5EA;
}
