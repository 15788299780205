/* Align text to the left of the checkbox */
.delete-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  justify-content: flex-start;
}

.delete-checkbox input {
  order: 2; /* Move the checkbox to the right */
  width: 16px; /* Adjust the size of the checkbox */
  height: 16px; /* Adjust the size of the checkbox */
  margin: 0; /* Remove extra margin around the checkbox */
}

.delete-checkbox span {
  order: 1; /* Keep the text on the left */
  font-size: 14px; /* Adjust font size for better alignment */
  line-height: 1.5; /* Ensure consistent vertical spacing */
  color: #333; /* Ensure text color is black */
}

/* General colors for container and text */
.account-container {
  color: #333; /* Default text color */
}

/* Title and section headers */
.account-title {
  color: #333; /* Ensure black text for "Account Settings" */
}

h3 {
  color: #333; /* Ensure black text for "Change Email," "Change Password," and "Delete Account" */
}

/* Inputs */
/* input[type="text"],
input[type="email"],
input[type="password"] {
  border-color: #d95d39;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #b84b31;
  box-shadow: 0 0 0 3px rgba(217, 93, 57, 0.25);
} */

/* Buttons */
button {
  background-color: #d95d39;
  border-color: #d95d39;
  color: #fff;
}

button:hover {
  background-color: #b84b31;
  border-color: #b84b31;
}

/* Delete button styling */
.delete-button {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #fff;
}

.delete-button:hover {
  background-color: #b82c2a;
  border-color: #b82c2a;
}

/* Modal colors */
.modal-content {
  background-color: #fff;
  border-color: #d95d39;
}

.modal-content h3 {
  color: #d95d39;
}

.modal-confirm {
  background-color: #d95d39;
  border-color: #d95d39;
  color: #fff;
}

.modal-confirm:hover {
  background-color: #b84b31;
}

.modal-cancel {
  background-color: #ddd;
  color: #333;
}

.modal-cancel:hover {
  background-color: #bbb;
}

/* Status message */
.status-message {
  color: #d95d39;
}

/* Delete checkbox colors */
.delete-checkbox input {
  accent-color: #d95d39; /* Use this for checkbox color in modern browsers */
}

/* General button styling */
.account-container button {
  background-color: #d95d39 !important; /* Force your custom color */
  border-color: #d95d39 !important;
  color: #fff !important;
  font-family: inherit; /* Ensure consistent font */
  font-size: 14px; /* Adjust font size */
  padding: 8px 16px; /* Add padding for better appearance */
  border-radius: 4px; /* Smooth corners */
  cursor: pointer; /* Pointer cursor for better UX */
}

/* Hover state for all buttons */
.account-container button:hover {
  background-color: #b84b31 !important;
  border-color: #b84b31 !important;
}

/* Modal confirm button styling */
.modal-confirm {
  background-color: #d95d39 !important;
  /*border-color: #d95d39 !important;*/
  color: #fff !important;
}

/* Modal cancel button styling */
.modal-cancel {
  background-color: #ddd !important;
  color: #333 !important;
}

/* Modal cancel button hover */
.modal-cancel:hover {
  background-color: #bbb !important;
}

.custom-dropdown-toggle {
  background-color: #D95D39 !important; /* Orange color */
  color: #ffffff !important; /* White text */
  border: none !important; /* Remove default border */
  border-radius: 5px !important; /* Rounded corners */
  padding: 8px 16px !important; /* Adjust padding */
  font-size: 14px !important; /* Adjust font size */
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus {
  background-color: #c04e2e !important; /* Slightly darker orange on hover */
  color: #ffffff !important; /* Ensure text remains white */
}

.custom-dropdown-toggle i {
  margin-right: 8px; /* Add spacing between the icon and text */
}
