.settings-layout {
    display: flex;
    height: 100vh;
}

.settings-sidebar {
    width: 250px;
    background-color: #f8f9fa;
    padding: 1rem;
    border-right: 1px solid #e5e5e5;
}

.settings-group-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.settings-nav {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
}

.settings-nav-item {
    margin-bottom: 0.5rem;
}

.nav-link {
    text-decoration: none;
    color: #1e293b;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    display: block;
    border-radius: 0.25rem;
}

.nav-link.active {
    background-color: #f97316;
    color: white;
}

.settings-content {
    flex: 1;
    padding: 2rem;
}


